/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import * as React from "react";



const SingleProductDetail = () => {
  const { productName } = useParams<{ productName: string }>();
    console.log("PRODYCT NAME",productName)
  return (
    <div className="card mb-5 mb-xl-10">
      <PageTitle >
          {productName}
      </PageTitle>
    </div>
  );
};

const SingleProductWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <SingleProductDetail />
    </>
  );
};

export { SingleProductWrapper };
