/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  supabase,
  updateProductMenuInLocal,
} from "../../modules/auth/components/supabase";
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { KTIcon, KTSVG } from "../../../_metronic/helpers";
import { QRCode } from "react-qrcode-logo";
import * as React from "react";
import dayjs from "dayjs";
import axios from "axios";

const keys = localStorage.key(0);
const supabaseToken = localStorage.getItem(keys!);

const parsedToken = JSON.parse(supabaseToken!);

const ProductDetail = () => {
  const [products, setProducts] = useState(null);
  const [loader, setLoader] = useState(true);
  const [subscribedPlanIds, setSubscribedPlanIds] = useState<any[]>();
  const [organizationId, setOrganizationId] = useState<string>("");
  useEffect(() => {
    setLoader(true);
    setOrganizationId(
      JSON.parse(localStorage.getItem("organization") as string).id
    );
    fetchProducts();
  }, []);
  const authUser = async () => {
    const user = await supabase.auth.getUser();
  };
  const fetchProducts = async () => {
    try {
      const { data: products, error: productError } = await supabase
        .from("products")
        .select(`*, plans(*)`);

      if (productError) {
        throw productError;
      }
      await authUser();
      await getSubscriptions();
      // @ts-ignore
      setProducts(products);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getSubscriptions = async () => {
    // @ts-ignore
    const { data } = await supabase
      .from("organization_subscriptions")
      .select(`plan_id, organization_id`);
    let subscribedPlanIds: string[] = [];
    data!.map((d: any) => {
      subscribedPlanIds.push(d.plan_id);
    });
    setSubscribedPlanIds(subscribedPlanIds);
  };
//   if (loader || !products) {
//     return <div>Loading...</div>;
//   }

  const subscribePlan = async (id: string, organizationId: string) => {
    setLoader(true);
    setLoader(true);
    supabase.functions
      .invoke("subscribe-products", {
        body: {
          organizationId,
          planId: id,
        },
      })
      .then(() => {
        updateProductMenuInLocal().then(() => {
          window.location.reload();
        });
      });
  };

  return (
    <div className="row g-5">
      {/* @ts-ignore */}
      {products && products!.map((product: any) => {
        var buttonName = "Subscribe";
        if (
          JSON.parse(localStorage.getItem("productName") as string).includes(
            product.name
          )
        ) {
          buttonName = "Switch Plan";
        }
        return (
          <div key={product.id} className="col-xl-4">
            <div className={`card-xl-stretch mb-xl-8`}>
              <div className="card-body p-0">
                <div
                  className={`px-9 pt-7 card-rounded h-275px w-100 bg-primary`}
                >
                  <div className="d-flex flex-stack">
                    <h3 className="m-0 text-white fw-bold fs-3">
                      {product.name}
                    </h3>
                    <div className="ms-1">
                      <button
                        type="button"
                        className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-primary border-0 me-n3`}
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end"
                      >
                        <KTIcon iconName="category" className="fs-2" />
                      </button>
                    </div>
                  </div>

                  <div className="d-flex text-center flex-column text-white pt-8">
                    <span className="fw-semibold fs-7">
                      {product.description}
                    </span>
                  </div>
                </div>

                <div
                  className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                  style={{ marginTop: "-100px" }}
                >
                  {product.plans.map((plan: any) => {
                    // @ts-ignore
                    const button = subscribedPlanIds.includes(plan.id)
                      ? {
                          className: "btn btn-success disabled",
                          text: "Subscribed",
                        }
                      : { className: "btn btn-primary", text: buttonName };
                    return (
                      <div
                        key={plan.id}
                        className="d-flex align-items-center mb-6"
                      >
                        <div className="symbol symbol-45px w-40px me-5">
                          <span className="symbol-label bg-lighten">
                            <KTIcon iconName="compass" className="fs-1" />
                          </span>
                        </div>

                        <div className="d-flex align-items-center flex-wrap w-100">
                          <div className="mb-1 pe-3 flex-grow-1">
                            <a
                              href="#"
                              className="fs-5 text-gray-800 text-hover-primary fw-bold"
                            >
                              {`${plan.name}(${plan.price}₹)`}
                            </a>
                            <div className="text-gray-500 fw-semibold fs-7">
                              {plan.description}
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <button
                              className={button.className}
                              onClick={() =>
                                subscribePlan(plan.id, organizationId)
                              }
                            >
                              {loader ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                button.text
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ProductDetailWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.PRODUCT_DETAILS" })}
      </PageTitle>
      <ProductDetail />
    </>
  );
};

export { ProductDetailWrapper };
