/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import {setOrganization, supabase, updateProductMenuInLocal} from './supabase';
import {useNavigate} from 'react-router-dom';
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers';

const loginSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const initialValues = {
    email: '',
    password: '',
};

export function Login() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {saveAuth, setCurrentUser} = useAuth();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            formik.setStatus('');
            setLoading(true);
            try {
                const {
                    data: {user},
                    error: userError,
                } = await supabase.auth.signInWithPassword({
                    email: values.email,
                    password: values.password,
                });

                if (userError) throw userError;
                await setOrganization();
                await updateProductMenuInLocal()

                if (user) {
                    navigate('/dashboard');
                }
            } catch (error) {
                saveAuth(undefined);
                // @ts-ignore
                setErrorMessage(JSON.stringify(error.message));
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
            <div className="text-center mb-11">
                <img
                    alt="Logo"
                    src={toAbsoluteUrl('/media/logos/favicon.ico')}
                    className="h-100px app-sidebar-logo-default theme-light-show"
                />
            </div>

            <div className="fv-row mb-8">
                <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                <input
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.email}</span>
                    </div>
                )}
            </div>

            <div className="fv-row mb-3">
                <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                <input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div/>
            </div>

            <div className="d-grid mb-10">
                <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className="indicator-label">Continue</span>}
                    {loading && (
                        <span className="indicator-progress" style={{display: 'block'}}>
							Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"/>
						</span>
                    )}
                </button>
                <br/>
                {errorMessage && (
                    <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                            <span className="text-danger fs-4">{errorMessage}</span>
                            <button className="btn btn-link text-danger fs-1" onClick={() => setErrorMessage('')}>
                                <KTIcon iconType="duotone" iconName="cross-square" className="fs-1"/>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
}

