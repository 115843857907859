/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from "react-intl";
import {SidebarMenuItem} from "./SidebarMenuItem";


const SidebarMenuMain = () => {
    const intl = useIntl();
    const productListString = localStorage.getItem("productName")
    const productList = JSON.parse(productListString!);

    return (
        <>
            <SidebarMenuItem
                to="/dashboard"
                icon="element-11"
                title={intl.formatMessage({id: "MENU.USERS"})}
                fontIcon="bi-app-indicator"
            />
            <SidebarMenuItem
                to="/products"
                icon="element-11"
                title={intl.formatMessage({id: "MENU.PRODUCTS"})}
                fontIcon="bi-app-indicator"
            />
            {productList!.map((product: string, index: number) => (
                <SidebarMenuItem
                    to={"/"+product}
                    icon="element-11"
                    title={product}
                    fontIcon="bi-app-indicator"
                />
            ))}
        </>
    );
};

export {SidebarMenuMain};
