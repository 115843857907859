import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl as string, supabaseAnonKey as string);


export const updateProductMenuInLocal= async () => {
    const { data, error } = await supabase.from('organization_subscriptions').select('plan_id(product_id(name))');
    console.log("SUBSCRIPTIONS",data);
    if (data) {
        //@ts-ignore
        const uniqueValues = [...new Set(data.map(obj => obj.plan_id.product_id.name))];
        localStorage.setItem('productName', JSON.stringify(uniqueValues));
    }
};

export const setOrganization = async()=>{
    const { data:organization, error:organizationError } = await supabase.from('organizations').select('id,name,logo').single();
    if(organization){
        localStorage.setItem('organization', JSON.stringify(organization));
    }
}

export const clearLocalStorageOnSingout = async() =>{
    localStorage.removeItem('organization');
    localStorage.removeItem('productName');
}