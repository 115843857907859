import { useLocation, useParams } from 'react-router-dom';

const ToolbarClassic = () => {
	const location = useLocation();
	const { projectId } = useParams();
	return null;

	if (location.pathname === '/dashboard') {
		return (
			<div className="d-flex align-items-center gap-2 gap-lg-3">
				<a href="/project/add" className="btn btn-sm fw-bold btn-primary">
					Create
				</a>
			</div>
		);
	} else if (location.pathname.startsWith('/project/') && location.pathname.endsWith('/detail')) {
		return (
			<div className="d-flex align-items-center gap-2 gap-lg-3">
				<a href={`/project/${projectId}/details/add`} className="btn btn-sm fw-bold btn-primary">
					Create Drawing
				</a>
			</div>
		);
	} else {
		return null;
	}
};

export { ToolbarClassic };
