/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../../_metronic/layout/core";
import {supabase} from "../../modules/auth/components/supabase";

const User = () => {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const navigate = useNavigate();

    const btnRef = useRef<HTMLButtonElement | null>(null);
    const addUser = (data: any) => {
        btnRef.current?.setAttribute("data-kt-indicator", "on");
        supabase.functions.invoke('register-user-in-org',{
            body:{
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "password": password
            }
        }).then(()=>{
            navigate("/dashboard");
            btnRef.current?.removeAttribute("data-kt-indicator");
        })

    };

    return (
        <>
            <div className="mb-10">
                <label className="form-label">Email</label>
                <input
                    type="email"
                    required
                    className="form-control form-control-white"
                    placeholder="Type User Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="mb-10">
                <label className="form-label">First Name</label>
                <input
                    type="text"
                    className="form-control form-control-white"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>

            <div className="mb-10">
                <label className="form-label">Last Name</label>
                <input
                    type="email"
                    className="form-control form-control-white"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>

            <div className="mb-10">
                <label className="form-label">Password</label>
                <input
                    type="password"
                    required
                    className="form-control form-control-white"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <button
                ref={btnRef}
                onClick={addUser}
                type="button"
                className="btn btn-primary me-10"
                id="kt_button_1"
            >
                <span className="indicator-label">Add User</span>
                <span className="indicator-progress" style={{"cursor": "no-drop"}}>
          Please wait...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
            </button>
        </>
    );
};

const UserWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: "MENU.USER"})}
            </PageTitle>
            <User/>
        </>
    );
};

export {UserWrapper};
