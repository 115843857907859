import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import { NewProjectWrapper } from '../pages/projects/AddNewProject';
import { ProjectDetailWrapper } from '../pages/projects/ProjectDetailView';
import { AddProjectDetailWrapper } from '../pages/projects/AddProjectDrawing';
import { ProjectDrawingEditWrapper } from '../pages/projects/EditProjectDrawing';
import { ProjectEditWrapper } from '../pages/projects/EditProject';
import { DrawingVersionWrapper } from '../pages/projects/AddDrawingVersion';
import { ProductDetailWrapper } from '../pages/products/ProductDetails';
import { UserWrapper } from '../pages/users/AddUser';
import {SingleProductWrapper} from "../pages/projects/ProductDetailDetailView";

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
	const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path="auth/*" element={<Navigate to="/dashboard" />} />
				{/* Pages */}
				<Route path="dashboard" element={<DashboardWrapper />} />
				<Route path="/user/add" element={<UserWrapper />} />
				<Route path="products" element={<ProductDetailWrapper />} />
				<Route path=":productName" element={<SingleProductWrapper />} />
			</Route>
		</Routes>
	);
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary');
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	});
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
