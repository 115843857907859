import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { Logout, AuthPage } from '../modules/auth';
import { App } from '../App';
import Protected from '../../utils/Helper/Protected';

const AppRoutes: FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<App />}>
					<Route path="auth/*" element={<AuthPage />} />
					<Route element={<Protected />}>
						<Route path="/*" element={<PrivateRoutes />} />
						<Route path="/" element={<Navigate to="/dashboard" />} />
						<Route path="/" element={<Navigate to="/user/add" />} />
						<Route path="/" element={<Navigate to="/products" />} />
						<Route path="/" element={<Navigate to="/project/add" />} />
						<Route path="/" element={<Navigate to="/project/:projectId/detail" />} />
						<Route path="/" element={<Navigate to="/project/:projectId/details/add" />} />
						<Route path="/" element={<Navigate to="/project/:drawingId/drawing/edit" />} />
						<Route path="/" element={<Navigate to="/project/:drawingId/drawing_version/edit" />} />
						<Route path="/" element={<Navigate to="/project/:projectId/edit" />} />
						<Route path="/logout" element={<Logout />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
